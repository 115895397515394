
import React from 'react';

import GlobalNav from "../navigation/GlobalNav"
import Footer from "../navigation/Footer"
import ConsultationRequestForm from "../../components/forms/ConsultationRequestForm"

import Container from 'react-bootstrap/Container'

export default function Tier2Layout({ children,showConsultForm,onCloseConsultForm }) {

  return (
      <>

        <Container className="no-gutter">
            <GlobalNav /> 
        </Container>
        <Container className="no-gutter">
            {children}
            <ConsultationRequestForm show={showConsultForm} onClose={onCloseConsultForm} />
        </Container>
        <Footer />
    </>
  )
}








