import React,{ useEffect,useState } from 'react'
import { useLocation } from "@reach/router"

import SEOMetaData from '../../components/background/SEOMetaData.js';
import {InterestTracker} from "../../components/background/InterestTracker.js";
import GoogleAnalyticsEvent from "../../components/background/GoogleAnalyticsEvent.js";
import { Link } from 'gatsby'


import Tier2Layout from "../../components/content/Tier2Layout"
import ConsultationRequestTrigger from "../../components/forms/ConsultationRequestTrigger"
import PageSectionHeadline from "../../components/content/PageSectionHeadline"
import Subheading from "../../components/content/Subheading"
import NewsletterSubscriptionForm from '../../components/forms/NewsletterSubscriptionForm'; 

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import '../../styles/tier2.css'
import '../../styles/groups.css'
import groups_hero from '../../images/groups-hero-1a.jpg';
import preview_bffs from '../../images/groups-ideas-bff-1.jpg';
import preview_family from '../../images/groups-ideas-family-1.jpg';
import preview_corpo from '../../images/groups-ideas-corpo-1.jpg';


const GroupsIndex = () => {
    const pageData = {
                        seo:{
                            title:'Specialists in Group Travel',
                            description:"Travel is better with others, but it can be complicated to plan. Outward Travel specializes in group travel - we'll handle all the coordination for you, so you and yours can have a great time.",
                            image:'/images/groups-hero-1.jpg'
                        }
                    }; 


    let location = useLocation(); 
    useEffect(() => {
        InterestTracker('Groups',location); 
    },[location]);

    const [showConsultationForm,setShowConsultationForm] = useState(false); 

    function startConsultationRequest(which){ 
        GoogleAnalyticsEvent("start_consultationrequest",{buttonLocation:which})
        setShowConsultationForm(true); 
    }
    
  return (
    <main>
        <SEOMetaData title={pageData.seo.title} description={pageData.seo.description} image={pageData.seo.image} />
        <Tier2Layout showConsultForm={showConsultationForm} onCloseConsultForm={()=>{setShowConsultationForm(false)}}>

        <div id="Groups_Hero">
            <div className="mast-seperator">
                <svg id="Tier2MastSeperatorSVG" data-name="Tier2MastSeperatorSVG" xmlns="http://www.w3.org/2000/svg" width="1320" height="96.8978" viewBox="0 0 1320 96.8978">
                <path class="background" d="M1320,51.7988H136.6818C1.0792,51.7988,0,96.8978,0,96.8978V-.3547H1320Z"/>
                <path class="middle-bar" d="M1320,38.7957H94.0934c-80.149,0-94.0934,20-94.0934,20V4.07s3.7773,20,92.9468,20H1320Z"/>
                <path class="upper-bar" d="M1.586,1.4134S17.79,20.75,95.2027,20.75C100.97,20.75,1320,21.0276,1320,21.0276V15.0245S114.4532,15.0121,95.8607,15.0121C20.0737,15.0121,1.586,1.4134,1.586,1.4134Z"/>
                <path class="lower-bar" d="M.0355,66.3013c9.1191-12.2588,35.4369-24.373,103.9433-24.2294,9.7635.02,1216.0212-.2762,1216.0212-.2762v6.0031s-1164.73,1.19-1183.3222,1.19C.2923,48.989,0,91.41,0,91.41Z"/>
                <polygon class="right-arrow" points="1315.675 48.499 1320 48.499 1320 10.898 1278.546 10.898 1315.675 48.499"/>
                </svg>
            </div>
            <div className="image">
                <img src={groups_hero} width="100%" alt="Group of Friends Taking a Selfie" />
            </div>
            <div className="textcopy">
                <h1>Squad Up! <span>See The World Together</span></h1>
                <p className="hidden-xs hidden-sm hidden-md"><span>Go on an adventure with Friends,</span> <span>Reconnect with family,</span> <span>Organize a club trip,</span> <span>Reward your employees.</span></p>
                <p className="hidden-xs hidden-sm"><span>Travel is better with others.</span> Outward Travel makes it easy.</p>
            </div>
        </div>

        <Row>
            <Col md={8} className="Tier2MainText">
                <p>Travel is better with others. Best friends, families of choice, or multigenerational family reunions&mdash;go
                    see the world with whomever you love!
                </p>
                <p>Outward Travel specializes in helping groups of any size plan a trip that everyone will love. 
                    A group trip can be complex. Everyone has different interests, and moves at different speeds. 
                    Managing a bunch of reservations, making sure everyone pays their fair share on time, and 
                    coordinating everyone's schedules can really be a pain. Outward makes all of that easy for you.
                </p>
                <p>We'll help plan a trip that meets everyone's expectations and all of their unique needs. We'll coordinate
                    everyone's schedules, make sure they have their documents ready, collect their payments. We'll make
                    reservations, plan custom excursions, and connect you with in-country partners who can help
                    get you where you want to go. 
                </p>
            </Col>
            <Col md={4}>
                <NewsletterSubscriptionForm disposition="inline" format="vertical" color="blue">
                    <h6>Tips, Tricks, &amp; Ideas</h6>
                    <p>Subscribe to our email list to get the latest ideas for a great trip, and tips for groups traveling together!</p>
                </NewsletterSubscriptionForm>
            </Col>
        </Row>

        <PageSectionHeadline color="blue" id="IdeasListSubHeading">Vacations Are Better With Others</PageSectionHeadline>

        <Row className="featured-ideas-grid-row">
            <Col className="groupsideas-image" md={4}>
                <img src={preview_bffs} width="100%" alt="Group of ladies enjoying a picnic on vacation" />
            </Col>
            <Col className="groupsideas-text" md={8}>
                <Subheading color="green">Best Friends Getaways</Subheading>
                <p>Wishing for a getaway with your friends? It's time to make that happen.</p>
                <p>Get the girls and jet off to a <Link to="/vacations/spiritual?view=riviera-maya">tropical beach resort</Link>, where you start your day with mimosas and yoga, and end it dancing the night away 
                    and laughing about old times. Call the bros and live it up in Las Vegas, or go golfing on world-class 
                    links. Experience <Link to="/vacations/lgbtq?view=puerto-rico">Puerto Rico with your family of choice</Link> or 
                    party at sea on a long weekend with the <Link to="/vacations/cruises?view=virgin">chic and stylish Virgin Voyages</Link>.</p>
                <p>
                    Whatever you've been wishing for, Outward Travel is ready to help make it happen.
                </p>

                <ConsultationRequestTrigger color="green" size="sm" 
                    text={<>Start Planning with your BFFs</>} 
                    onClick={()=>{startConsultationRequest('groupsindex_bffs')}} />
            </Col>
        </Row>


        <Row className="featured-ideas-grid-row">
            <Col className="groupsideas-image" md={4}>
                <img src={preview_family} width="100%" alt="Family Reunion at Sea" />
            </Col>
            <Col className="groupsideas-text" md={8}>
                <Subheading color="red">Family Reunions</Subheading>
                <p>Has it been too long since you spent quality time with your family?</p>
                <p>It's time to ditch the Zoom celebrations and be together for those special moments. 
                    When it comes to big families, we know it can be tough to find something for everyone to do. Consider <Link to="/vacations/cruises">going on a 
                    cruise together</Link>&mdash;for an all-inclusive price, there's something for everyone to do onboard and onshore. 
                    And of course, <Link to="/vacations/disney">Walt Disney World</Link><sub><small>&reg;</small></sub> is always 
                    magical. 
                    Outward Travel will help you find the best rates, the best options, and navigate the complex COVID-19 requirements and restrictions
                    so everyone has a good time. 
                </p>

                <ConsultationRequestTrigger color="red" size="sm" 
                    text={<>Let's Talk About Your Family</>} 
                    onClick={()=>{startConsultationRequest('groupsindex_bffs')}} />
            </Col>
        </Row>


        <Row className="featured-ideas-grid-row">
            <Col className="groupsideas-image" md={4}>
                <img src={preview_corpo} width="100%" alt="Young Professionals on a Vacation" />
            </Col>
            <Col className="groupsideas-text" md={8}>
                <Subheading color="blue">Corporate Rewards and Incentives</Subheading>
                <p>Now more than ever, it's time to reward your employees.</p>
                <p>The corporate world has spent the last couple years working hard to keep business going. Everything has
                    been harder while your employees balance meetings and home life. Your employees are tired of seeing
                    each other's kitchens and home offices. As the world reopens, reward your team with a group trip 
                    to somewhere they can unwind, refocus, and reconnect. Leverage Outward Travel's <Link to="/events">Events Planning</Link> services to build in a strategic agenda, or simply say "thank you" to your hard-working staff.
                </p>

                <ConsultationRequestTrigger color="blue" size="sm" 
                    text={<>Take Your Team Somewhere Special</>} 
                    onClick={()=>{startConsultationRequest('groupsindex_bffs')}} />
            </Col>
        </Row>

        </Tier2Layout>
    </main>
  )
}

 
export default GroupsIndex;
