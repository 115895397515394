
import React from 'react';

export default function PageSectionHeadline({ children,color,id }) {

  return (
      <>
    <div className={"section-headline "+color} id={id}>
        <h2>{children}</h2>
        <svg data-name="Headline BG" xmlns="http://www.w3.org/2000/svg" width="2001" height="59" viewBox="0 0 2001 59">
            <path class="text-bg" d="M875.8819,59" transform="translate(1.1181)"/>
            <path class="inner-swoop" d="M863.8819,59h-21s-13-3-24-18a112.5423,112.5423,0,0,1-17-36s5,15,23,33C843.3886,56.5068,863.8819,59,863.8819,59Z" transform="translate(1.1181)"/>
            <path class="text-bg" d="M836.8819,59h-838V0h798s4.3584,17.6655,11,30C821.8819,56,836.8819,59,836.8819,59Z" transform="translate(1.1181)"/>
            <path class="outer-swoop" d="M899.8819,59h-27s-23.2887-4.6309-40-18c-20-16-25-31-25-31s11,21,33,33c26.0574,14.2131,59,15,59,15Z" transform="translate(1.1181)"/>
            <rect class="keyline" x="901" y="58" width="1100" height="1"/>
            <polygon class="left-angle" points="0 0 0 60 30 60 0 0"/>
        </svg>
    </div>
    </>
  )
}
