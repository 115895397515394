
import React from 'react';
import styled from "styled-components"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from 'react-bootstrap/Button'

import { faComments } from '@fortawesome/free-solid-svg-icons'

const TriggerButton = styled(Button)`
	display:block; 
	background-color:#008BE0; 
    border:0px; 
    height:60px; 
    z-index:10; 
	border-radius: 30px;
    color: white;
    outline: none;
    font-size:1.1em; 
    text-align:left; 
    width:370px; 
    padding-right:20px; 
    padding-left:15px; 
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    @media  (max-width:767.98px) {  
        width:270px; 
    }
    &.center{ 
        margin:0 auto 10px auto; 
    }
	&:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
	&.green{ 
		background-color:#169300; 
	} 
	&.blue{ 
		background-color:#008BE0; 
	} 
	&.yellow{ 
        background-color:#fcae00; 
        color:#000000; 
	} 
	&.red{ 
        background-color:#a50000; 
	} 
	&.orange{ 
        background-color:#C96E00; 
	} 
	&.purple{ 
        background-color:#7500C9; 
	} 
	&.right{ 
		float:right; 
		clear:left; 
    } 
    &.hidden{ 
        display:none; 
    }
    &:disabled{ background-color:#999999; box-shadow:none; }
    & > svg{ 
        margin-right:15px; 
        float:left; 
        margin-top:5px; 
    }
    &.sm{ 
      height:40px; 
      width:auto;   
    }
    &.sm > svg{ 
        margin-top:0px;  
    }
    & > small{ 
        display:block; 
        font-size:0.7em; 
    }
`;
export default function ConsultationRequestTrigger({color,onClick,size,text,align}) {

    if(color===undefined) color=""; 
    if(size===undefined) size=""; 
    if(align===undefined) align="center"; 

    // let buttonClass = buttonColor;

    return (
        <TriggerButton onClick={e => { onClick(e);  }} className={color+' '+size+' '+align}>
            <FontAwesomeIcon icon={faComments} size={(size==='sm')?'lg':"2x"} /> 
            {(text===undefined) ? <>Let's Get Started! 
            <small>Request a free consultation <span className="hidden-xs hidden-sm">with Outward Travel</span></small></>
            :
            text}
        </TriggerButton>
    )
}






