
import React from 'react';
import styled from "styled-components"; 

const Subhead = styled.div`
    display:inline-block; 
    position:relative; 
    height:43px; 
    overflow:hidden; 
    padding-right:40px;

    & h3{ 
        display:inline-block; 
        position:relative; 
        z-index:20; 
        color:#FFFFFF; 
        margin:7px 0px 0px 10px; 
        font-size:1.4rem; 
    }
    & h3 a{ 
        color:#FFFFFF; 
        text-decoration:none; 
    }
    & svg{ 
        position:absolute; 
        top:0px; 
        right:0px; 
        z-index:10; 
    }
    &.blue svg .drop-shadow {
        fill: #004c7a;
    }
    &.blue svg .main-bg, 
    &.blue svg .drop-shadow {
        fill-rule: evenodd;
    }
    &.blue svg .main-bg {
        fill: #008BE0;
    }
    &.yellow h3{ 
        color:#212529;
    }
    &.yellow h3 a{ 
        color:#212529; 
        text-decoration:none; 
    }
    &.yellow svg .drop-shadow {
        fill: #C96E00;
    }
    &.yellow svg .main-bg, 
    &.yellow svg .drop-shadow {
        fill-rule: evenodd;
    }
    &.yellow svg .main-bg {
        fill: #fcae00;
    }

    &.green h3{ 
        color:#FFFFFF;
    }
    &.green svg .drop-shadow {
        fill: #005600;
    }
    &.green svg .main-bg, 
    &.green svg .drop-shadow {
        fill-rule: evenodd;
    }
    &.green svg .main-bg {
        fill: #169300;
    }

    &.purple h3{ 
        color:#FFFFFF;
    }
    &.purple svg .drop-shadow {
        fill: #3C0070;
    }
    &.purple svg .main-bg, 
    &.purple svg .drop-shadow {
        fill-rule: evenodd;
    }
    &.purple svg .main-bg {
        fill: #7500C9;
    }


    &.red h3{ 
        color:#FFFFFF;
    }
    &.red svg .drop-shadow {
        fill: #660000;
    }
    &.red svg .main-bg, 
    &.red svg .drop-shadow {
        fill-rule: evenodd;
    }
    &.red svg .main-bg {
        fill: #a50000;
    }

`;


export default function Subheading({ color,children }) {

  if(color===undefined) color="blue"; 
  return (
    <Subhead className={color} >
            <h3>{children}</h3>
            <svg data-name="Subheading BG" xmlns="http://www.w3.org/2000/svg" width="587" height="43" viewBox="0 0 587 43"><polygon class="drop-shadow" points="574 43 0 43 0 4 587 4 574 43"/><polygon class="main-bg" points="570 39 0 39 0 0 583 0 570 39"/></svg>
    </Subhead>
  )
}








